import {PermissionCode, ResourceCode} from '@/const/code'

export default [
    {
        path: '/admission/admission-round',
        name: 'admissionRound',
        component: () => import('@/views/admission/admission-round/Index.vue'),
        meta: {
            pageTitle: 'Quản lý đợt tuyển sinh',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý đợt tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_ROUND,
        },
    },
    {
        path: '/admission/admission-committee',
        name: 'admissionCommittee',
        component: () => import('@/views/admission/admission-committee/Index.vue'),
        meta: {
            pageTitle: 'Quản lý hội đồng tuyển sinh',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý hội đồng tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_COMMITTEE,
        },
    },
    {
        path: '/admission/admission-form',
        name: 'admissionForm',
        component: () => import('@/views/admission/admission-form/Index.vue'),
        meta: {
            pageTitle: 'Quản lý phương thức tuyển sinh',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý phương thức tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_FORM,
        },
    },
    {
        path: '/admission/admission-threshold-score',
        name: 'admissionThresholdScore',
        component: () => import('@/views/admission/admission-threshold-score/Index.vue'),
        meta: {
            pageTitle: 'Quản lý điểm sàn',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý điểm sàn',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_THRESHOLD_SCORE,
        },
    },
    {
        path: '/admission/admission-major',
        name: 'admissionMajor',
        component: () => import('@/views/admission/admission-major/Index.vue'),
        meta: {
            pageTitle: 'Quản lý ngành tuyển sinh',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý ngành tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_MAJOR,
        },
    },
    {
        path: '/admission/admission-priority-score',
        name: 'admissionPriorityScore',
        component: () => import('@/views/admission/admission-priority-score/Index.vue'),
        meta: {
            pageTitle: 'Điểm quy đổi, điểm ưu tiên',
            breadcrumb: [
                {
                    text: 'Tuyển sinh',
                    active: false,
                },
                {
                    text: 'Điểm quy đổi, điểm ưu tiên',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_PRIORITY_SCORE,
        },
    },
    {
        path: '/admission/admission-revenue-type',
        name: 'admissionRevenueType',
        component: () => import('@/views/admission/admission-revenue-type/Index.vue'),
        meta: {
            pageTitle: 'Loại khoản thu tuyển sinh',
            breadcrumb: [
                {
                    text: 'Quản lý khoản thu tuyển sinh',
                    active: false,
                },
                {
                    text: 'Loại khoản thu tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_REVENUE_TYPE,
        },
    },
    {
        path: '/admission/admission-revenue',
        name: 'admissionRevenue',
        component: () => import('@/views/admission/admission-revenue/Index.vue'),
        meta: {
            pageTitle: ' Khoản thu tuyển sinh',
            breadcrumb: [
                {
                    text: 'Quản lý khoản thu tuyển sinh',
                    active: false,
                },
                {
                    text: 'Khoản thu tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_REVENUE,
        },
    },
    {
        path: '/admission/criteria-set',
        name: 'criteriaSet',
        component: () => import('@/views/admission/criteria-set/Index.vue'),
        meta: {
            pageTitle: 'Bộ tiêu chí tuyển sinh',
            breadcrumb: [
                {
                    text: 'Quản lý bộ tiêu chí',
                    active: false,
                },
                {
                    text: 'Bộ tiêu chí tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.CRITERIA_SET,
        },
    },
    {
        path: '/admission/admission-revenue-plan',
        name: 'admissionRevenuePlan',
        component: () => import('@/views/admission/admission-revenue-plan/Index.vue'),
        meta: {
            pageTitle: 'Kế hoạch thu',
            breadcrumb: [
                {
                    text: 'Quản lý khoản thu tuyển sinh',
                    active: false,
                },
                {
                    text: 'Kế hoạch thu',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_REVENUE_PLAN,
        },
    },
    {
        path: '/admission/admission-language-score',
        name: 'admissionLanguageScore',
        component: () => import('@/views/admission/admission-language-score/Index.vue'),
        meta: {
            pageTitle: 'Khung điểm chứng chỉ ngoại ngữ',
            breadcrumb: [
                {
                    text: 'Quản lý tuyển sinh',
                    active: false,
                },
                {
                    text: 'Khung điểm chứng chỉ ngoại ngữ',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_LANGUAGE_SCORE,
        },
    },
    {
        path: '/admission/admission-confirmation-certificate-accept',
        name: 'admissionConfirmationCertificateAccept',
        component: () => import('@/views/admission/admission-confirmation-certificate-accept/Index.vue'),
        meta: {
            pageTitle: 'Quản lý Đối tượng ưu tiên',
            breadcrumb: [
                {
                    text: 'Quản lý tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý Đối tượng ưu tiên',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPT,
        },
    },
    {
        path: '/admission/admission-carousel',
        name: 'admissionCarousel',
        component: () => import('@/views/admission/admission-carousel/Index.vue'),
        meta: {
            pageTitle: 'Quản lý carousel tuyển sinh',
            breadcrumb: [
                {
                    text: 'Quản lý điểm tuyển sinh',
                    active: false,
                },
                {
                    text: 'Quản lý carousel tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_CAROUSEL,
        },
    },
    {
        path: '/admission/admission-confirm-certificate',
        name: 'admissionConfirmCertificate',
        component: () => import('@/views/admission/admission-confirm-certificate/AdmissionConfirmCertificate.vue'),
        meta: {
            pageTitle: 'Xác nhận chứng chỉ của Thí sinh ứng tuyển',
            breadcrumb: [
                {
                    text: 'Xác nhận chứng chỉ',
                    active: false,
                },
                {
                    text: 'Xác nhận chứng chỉ của Thí sinh ứng tuyển',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_CONFIRM_CERTIFICATE,
        },
    },
    {
        path: '/admission/formula-priority-score',
        name: 'formulaPriorityScore',
        component: () => import('@/views/admission/formula-priority-score/Index.vue'),
        meta: {
            pageTitle: 'Công thức tính điểm ưu tiên',
            breadcrumb: [
                {
                    text: 'Công thức tính điểm ưu tiên',
                    active: false,
                },
                {
                    text: 'Công thức tính điểm ưu tiên',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.FORMULA_PRIORITY_SCORE,
        },
    },
    {
        path: '/admission/formula-admission-score',
        name: 'formulaAdmissionScore',
        component: () => import('@/views/admission/formula-admission-score/Index.vue'),
        meta: {
            pageTitle: 'Công thức tính điểm tuyển sinh',
            breadcrumb: [
                {
                    text: 'Công thức tính điểm tuyển sinh',
                    active: false,
                },
                {
                    text: 'Công thức tính điểm tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.FORMULA_ADMISSION_SCORE,
        },
    },
    {
        path: '/admission/applicant-student-priority-order',
        name: 'applicantStudentPriorityOrder',
        component: () => import('@/views/admission/applicant-student-priority-order/ApplicantStudentPriorityOrder.vue'),
        meta: {
            pageTitle: 'Thứ tự ưu tiên của thí sinh ứng tuyển',
            breadcrumb: [
                {
                    text: 'Thứ tự ưu tiên',
                    active: false,
                },
                {
                    text: 'Thứ tự ưu tiên của thí sinh ứng tuyển',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.APPLICANT_STUDENT_PRIORITY_ORDER,
        },
    },
    {
        path: '/admission/aspiration-score',
        name: 'aspirationScore',
        component: () => import('@/views/admission/aspiration-scores/AspirationScores.vue'),
        meta: {
            pageTitle: 'Điểm theo từng nguyện vọng của thí sinh',
            breadcrumb: [
                {
                    text: 'Điểm theo từng nguyện vọng',
                    active: false,
                },
                {
                    text: 'Điểm theo từng nguyện vọng của thí sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ASPIRATION_SCORE,
        },
    },
    {
        path: '/admission/applicant-student-accepted',
        name: 'applicantStudentAccepted',
        component: () => import('@/views/admission/applicant-student-accepted/ApplicantStudentAccepted.vue'),
        meta: {
            pageTitle: 'Xét tuyển nguyện vọng',
            breadcrumb: [
                {
                    text: 'Xét tuyển nguyện vọng',
                    active: false,
                },
                {
                    text: 'Xét tuyển nguyện vọng',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.APPLICANT_STUDENT_ACCEPTED,
        },
    },
    {
        path: '/admission/admission-examination_schedulers',
        name: 'admissionExaminationScheduler',
        component: () => import('@/views/admission/admission-examination-scheduler/AdmissionExaminationScheduler.vue'),
        meta: {
            pageTitle: 'Dự kiến lịch thi tuyển sinh',
            breadcrumb: [
                {
                    text: 'Quản lý thi',
                    active: false,
                },
                {
                    text: 'Dự kiến lịch thi tuyển sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_EXAMINATION_SCHEDULER,
        },
    },
    {
        path: '/admission/admissionExaminationRoom',
        name: 'admissionExaminationRoom',
        component: () => import('@/views/admission/admission-examination-room/AdmissionExaminationRoom.vue'),
        meta: {
            pageTitle: 'Danh sách phòng thi theo môn thi',
            breadcrumb: [
                {
                    text: 'Quản lý thi',
                    active: false,
                },
                {
                    text: 'Danh sách phòng thi',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_EXAMINATION_ROOM,
        },
    },
    {
        path: '/admission/admissionExaminationSupervisorRoom',
        name: 'admissionExaminationSupervisorRoom',
        component: () => import('@/views/admission/admission-examination-room/supervisor-room/AdmissionSupervisorRoom.vue'),
        meta: {
            pageTitle: 'Danh sách phòng thi được phân công',
            breadcrumb: [
                {
                    text: 'Quản lý thi',
                    active: false,
                },
                {
                    text: 'Công tác Giám thị',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_SUPERVISOR_EXAMINATION_ROOM,
        },
    },
    {
        path: '/admission/applicantStudentExaminationRoom',
        name: 'applicantStudentExaminationRoom',
        component: () => import('@/views/admission/applicant-student-examination-room/ApplicantStudentExaminationRoom.vue'),
        meta: {
            pageTitle: 'Danh sách thí sinh',
            breadcrumb: [
                {
                    text: 'Quản lý thi tuyển',
                    active: false,
                },
                {
                    text: 'Danh sách thí sinh',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.APPLICANT_STUDENT_EXAMINATION_ROOM,
        },
    },
    {
        path: '/admission/examination/input-point-code/:id?',
        name: 'admissionExaminationInputPointByCode',
        component: () => import('@/views/admission/applicant-student-examination-room/ApplicantStudentExaminationPoint.vue'),
        meta: {
            pageTitle: 'Nhập điểm môn thi theo phách',
            breadcrumb: [
                {
                    text: 'Quản lý thi tuyển',
                    active: false,
                },
                {
                    text: 'Nhập điểm thi',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_EXAMINATION_POINT,
        },
    },
    {
        path: '/admission/examination/check-examination-score',
        name: 'admissionCheckExaminationScore',
        component: () => import('@/views/admission/applicant-student-examination-room/CheckExaminationScore.vue'),
        meta: {
            pageTitle: 'Phúc khảo điểm thi',
            breadcrumb: [
                {
                    text: 'Quản lý thi tuyển',
                    active: false,
                },
                {
                    text: 'Phúc khảo điểm thi',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_CHECK_EXAMINATION_SCORE,
        },
    },
    {
        path: '/admission-examination/scoreLock',
        name: 'admissionExaminationScoreLock',
        component: () => import('@/views/admission/score-lock/ScoreLockExaminationRoom'),
        meta: {
            pageTitle: 'Khóa/Mở điểm thi',
            breadcrumb: [
                {
                    text: 'Hệ thống',
                    active: false,
                },
                {
                    text: 'Khóa/Mở điểm thi',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.SCORE_LOCK_ADMISSION_EXAMINATION_ROOM,
        },
    },
    {
        path: '/admission/admissionStandardScore',
        name: 'admissionStandardScore',
        component: () => import('@/views/admission/admission-standard-score/AdmissionStandardScore.vue'),
        meta: {
            pageTitle: 'Xác nhận điểm chuẩn',
            breadcrumb: [
                {
                    text: 'Xác nhận điểm chuẩn',
                    active: false,
                },
                {
                    text: 'Xác nhận điểm chuẩn',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.ADMISSION_STANDARD_SCORE,
        },
    },
    {
        path: '/admission/applicantStudentConfirmAdmission',
        name: 'applicantStudentConfirmAdmission',
        component: () => import('@/views/admission/confirm-admission/ConfirmAdmission.vue'),
        meta: {
            pageTitle: 'Thí sinh xác nhận nhập học',
            breadcrumb: [
                {
                  text: 'Quản lý thí sinh xác nhận nhập học',
                  active: false,
                },
                {
                  text: 'Danh sách thí sinh xác nhận nhập học',
                  active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.APPLICANT_STUDENT_CONFIRM_ADMISSION,
        },
    },
    {
      path: '/admission/admission-revenue-schedule',
      name: 'admissionRevenueSchedule',
      component: () => import('@/views/admission/revenue-schedule/RevenueSchedule'),
      meta: {
        pageTitle: 'Quản lý khoản thu tuyển sinh',
        breadcrumb: [
          {
            text: 'Dự kiến thu',
            active: false,
          },
          {
            text: 'Dự kiến thu tuyển sinh',
            active: true,
          },
        ],
        action: PermissionCode.READ,
        resource: ResourceCode.ADMISSION_REVENUE_SCHEDULE,
      },
    },

]
