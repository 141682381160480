import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.setOfCriterias,
})

export default {
  namespaced: true,
  state: {
    setOfEvaluateLevels: [],
    setOfCriteriaTypes: [],
    setOfCriterias: [],
    totalRows: 0,
    statuses: STATUSES,
    resourceName: { fullName: 'Chương trình đào tạo', shortName: 'CTĐT' },
    maxOrderNo: 0,
    resultCheckCriterias: true,
    resultCheckCriteriaTypeSliders: true,
    dataResourecesExport: [],
    questionDifficultyLevels: [],
    criteriaTypes: [],
  },
  getters: {
    setOfCriteriaTypes: state => state.setOfCriteriaTypes,
    setOfEvaluateLevels: state => state.setOfEvaluateLevels,
    setOfCriterias: state => state.setOfCriterias,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    resultCheckCriterias: state => state.resultCheckCriterias,
    resultCheckCriteriaTypeSliders: state => state.resultCheckCriteriaTypeSliders,
    dataResourecesExport: state => state.dataResourecesExport,
    questionDifficultyLevels: state => state.questionDifficultyLevels,
    criteriaTypes: state => state.criteriaTypes,
  },
  mutations: {
    SET_SET_OF_EVALUATE_LEVELS: (state, setOfEvaluateLevels) => {
      state.setOfEvaluateLevels = setOfEvaluateLevels
    },
    SET_SET_OF_CRITERIA_TYPES: (state, setOfCriteriaTypes) => {
      state.setOfCriteriaTypes = setOfCriteriaTypes
    },
    SET_OF_CRITERIAS: (state, { total, records }) => {
      state.totalRows = total
      state.setOfCriterias = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_RESULT_CHECK_CRITERIAS: (state, resultCheckCriterias) => {
      state.resultCheckCriterias = resultCheckCriterias
    },
    SET_RESULT_CHECK_CRITERIA_TYPE_SLIDERS: (state, resultCheckCriteriaTypeSliders) => {
      state.resultCheckCriteriaTypeSliders = resultCheckCriteriaTypeSliders
    },
    SET_DATA_SOURCES_EXPORT: (state, data) => { state.dataResourecesExport = data },
    SET_QUESTION_DIFFICULTY_LEVELS: (state, data) => { state.questionDifficultyLevels = data },
    SET_CRITERIA_TYPES: (state, data) => { state.criteriaTypes = data },
  },
  actions: {
    async begin({ commit }, params) {
      try {
          const response = await axios.get(`${baseAttr.basePath}/begin`, { params })
          const { data } = response
          if (data.code === ApiCode.SUCCESS) {
              const { setOfCriteriaTypes, setOfEvaluateLevels, criteriaTypes, questionDifficultyLevels } = data.data
              commit('SET_SET_OF_CRITERIA_TYPES', setOfCriteriaTypes)
              commit('SET_SET_OF_EVALUATE_LEVELS', setOfEvaluateLevels)
              commit('SET_CRITERIA_TYPES', criteriaTypes)
              commit('SET_QUESTION_DIFFICULTY_LEVELS', questionDifficultyLevels)
          }
      } catch (e) {
          handleCatch(e)
      }
    },
    async getSetOfCriterias({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_OF_CRITERIAS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSetOfCriteria({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.basePath}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async copyProgramme({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.basePath}/copy/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSetOfCriteria({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSetOfCriteria({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async copySetOfCriteria({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.basePath}/copy/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async checkCriterias({ commit }, setOfCriteriaId) {
      try {
        const response = await axios.post(`${baseAttr.basePath}/checkCriterias/${setOfCriteriaId}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESULT_CHECK_CRITERIAS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async checkTypeSliders({ commit }, setOfCriteriaId) {
      try {
        const response = await axios.post(`${baseAttr.basePath}/checkTypeSliders/${setOfCriteriaId}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESULT_CHECK_CRITERIA_TYPE_SLIDERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async importSetOfCriteriaType({ commit }, params) {
      try {
          const response = await axios.post(`${baseAttr.basePath}/importSetOfCriteriaType`, { params })
          const { data } = response
          return data;
      } catch (e) {
          handleCatch(e)
      }
    },

    async getDataSourcesExport({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/exportSetOfCriteria`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_SOURCES_EXPORT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importSetOfCriteria({ commit }, params) {
      try {
          const response = await axios.post(`${baseAttr.basePath}/importSetOfCriteria`, { params })
          const { data } = response
          return data;
      } catch (e) {
          handleCatch(e)
      }
    },
  },
}
