import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/employee-evaluate/set-of-criteria-objects',
    name: 'employeeEvaluates',
    component: () => import('@/views/employee-evaluate/set-of-criteria-objects/List.vue'),
    meta: {
      pageTitle: 'Thực hiện khảo sát/đánh giá',
      breadcrumb: [
        {
          text: 'Khảo sát/đánh giá',
          active: false,
        },
        {
          text: 'Thực hiện khảo sát/đánh giá',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EMPLOYEE_EVALUATE,
    },
  },
]
